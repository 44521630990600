import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadContactProjects } from '../actions/contact';
import { createDeleteTemp } from '../actions/temp';

import { formatDate, formatMoney, makeCancelable, oauth, getUserImageSrc } from '../utils';

class ContactProjects extends Component{
  constructor(props) {
    super(props);

    this.state = {
      projects: Object.values(props.projects).sort((a, b) => b.job_number - a.job_number),
      sort: 'job_number',
      filterValue: '',
      filterType: '',
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeFilterType = this.handleChangeFilterType.bind(this);
    this.loadContactProjectsFromServer = this.loadContactProjectsFromServer.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ projects: this.state.sort == 'job_number' ? Object.values(nextProps.projects).sort((a, b) => b.job_number - a.job_number) : Object.values(nextProps.projects).sort((a, b) => b.date_created - a.date_created)});
  }

  UNSAFE_componentWillMount() {
    if(Object.values(this.props.projects).length == 0) {
      this.loadContactProjectsFromServer();
    }
  }

  componentDidMount() {
    this.props.onCreateDeleteTemp();
  }

  handleSort(e) {
    this.setState({ sort: e.target.value, filterValue: '', filterType: '' });
    if(e.target.value === 'job_number') {
      this.setState({ projects: Object.values(this.props.projects).sort((a, b) => b.job_number - a.job_number) });
    }else if(e.target.value === 'date_created') {
      this.setState({ projects: Object.values(this.props.projects).sort((a, b) => b.date_created - a.date_created) });
    }
  }

  handleChangeInput(e) {
    if(e.target.value == '') {
      if(this.state.filterType != '') {
        this.setState({
          filterValue: '',
          projects: this.state.sort === 'job_number' ? Object.values(this.props.projects).filter(p => p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ).sort((a, b) => b.job_number - a.job_number) :
                    this.state.sort === 'date_created' ? Object.values(this.props.projects).filter(p => p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ).sort((a, b) => b.date_created - a.date_created) :
                    this.props.projects
        });
      }else{
        this.setState({
          filterValue: '',
          projects: this.state.sort === 'job_number' ? Object.values(this.props.projects).sort((a, b) => b.job_number - a.job_number) :
                    this.state.sort === 'date_created' ? Object.values(this.props.projects).sort((a, b) => b.date_created - a.date_created) :
                    this.props.projects
        });
      }
    }else{
      if(this.state.filterType != '') {
        this.setState({
          filterValue: e.target.value,
          projects: this.state.sort === 'job_number' ? Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1
                    ).sort((a, b) => b.job_number - a.job_number)
                    : this.state.sort === 'date_created' ? Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1
                    ).sort((a, b) => b.date_created - a.date_created)
                    : Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 && p.order_types.indexOf(this.state.filterType.toUpperCase()) > -1
                    )
        });
      }else{
        this.setState({
          filterValue: e.target.value,
          projects: this.state.sort === 'job_number' ? Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                    ).sort((a, b) => b.job_number - a.job_number)
                    : this.state.sort === 'date_created' ? Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                    ).sort((a, b) => b.date_created - a.date_created)
                    : Object.values(this.props.projects).filter(p =>
                      p.job_number.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      p.job_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                    )
        });
      }
    }
  }

  handleChangeFilterType(e) {
    this.setState({ filterType: e.target.value });
    let original_type;
    if(e.target.value === 'presentation') {
      original_type = 'PRESENTATION';
    }else if(e.target.value === 'estimate') {
      original_type = 'ESTIMATE';
    }else if(e.target.value === 'sales_order') {
      original_type = 'SALES ORDER';
    }else if(e.target.value === 'invoice') {
      original_type = 'INVOICE';
    }

    if(e.target.value == '') {
      this.setState({ projects: this.state.sort === 'job_number' ?
        Object.values(this.props.projects).filter(p => p.job_number.toLowerCase().indexOf(this.state.filterValue) > -1 ||
          p.job_name.toLowerCase().indexOf(this.state.filterValue) > -1 ||
          (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1 ||
          (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1
        ).sort((a, b) => b.job_number - a.job_number)
      : Object.values(this.props.projects).filter(p => p.job_number.toLowerCase().indexOf(this.state.filterValue) > -1 ||
         p.job_name.toLowerCase().indexOf(this.state.filterValue) > -1 ||
         (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1 ||
         (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1
        ).sort((a, b) => b.date_created - a.date_created) });
    }else{
      this.setState({ projects: this.state.sort === 'job_number' ?
        Object.values(this.props.projects).filter(p => p.order_types.indexOf(original_type) > -1 && p.job_number.toLowerCase().indexOf(this.state.filterValue) > -1 ||
          p.order_types.indexOf(original_type) > -1 && p.job_name.toLowerCase().indexOf(this.state.filterValue) > -1 ||
          p.order_types.indexOf(original_type) > -1 && (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1 ||
          p.order_types.indexOf(original_type) > -1 && (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1
        ).sort((a, b) => b.job_number - a.job_number)
      : Object.values(this.props.projects).filter(p => p.order_types.indexOf(original_type) > -1 && p.job_number.toLowerCase().indexOf(this.state.filterValue) > -1 ||
         p.order_types.indexOf(original_type) > -1 && p.job_name.toLowerCase().indexOf(this.state.filterValue) > -1 ||
         p.order_types.indexOf(original_type) > -1 && (p.sales_rep.user_first_name + ' ' + p.sales_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1 ||
         p.order_types.indexOf(original_type) > -1 && (p.client_rep.user_first_name + ' ' + p.client_rep.user_last_name).toLowerCase().indexOf(this.state.filterValue) > -1
        ).sort((a, b) => b.date_created - a.date_created) });
    }
  }

  loadContactProjectsFromServer() {
    const data = {
      id: this.props.contact.company_contact_id,
      type: 'renderContactProjects'
    };
    const search = makeCancelable(oauth('GET', 'contact/renderContactProjects', data));
    search.promise.then(
      ({ json }) => {
        this.props.onloadContactProjects(json.projects);
      },
      ({ json }) => {
        console.log(json);
      }
    );
  }

  render() {
    const { projects, contact, onloadContactProjects, onCreateDeleteTemp } = this.props;

    let icon_index = 0;
    return(
      <div className="main-content">
        <div className="small-12 columns project-row row no-maxWidth">
          {/* <div className="small-3 columns">
            <select value={this.state.sort} onChange={this.handleSort}>
              <option value='job_number'>Sort by project number</option>
              <option value='date_created'>Sort by date created</option>
            </select>
          </div> */}
          <div className="small-3 columns">
            <input type={this.state.filterValue} placeholder="Filter by number, name, or rep…" style={{height: '2.4375rem', width: '100%'}}
              onChange={this.handleChangeInput} />
          </div>
          <div className="small-3 columns">
            <select value={this.state.filterType} onChange={this.handleChangeFilterType}>
              <option value=''>Order types</option>
              <option value='presentation'>Presentation</option>
              <option value='estimate'>Estimate</option>
              <option value='sales_order'>Sales Order</option>
              <option value='invoice'>Invoice</option>
            </select>
          </div>
          <div className="small-3 columns">
            &nbsp;
          </div>
        </div>
        {Object.values(this.state.projects).map(p =>
          <div className="small-12 columns project-row row no-maxWidth" key={p.job_id}>
            <div className="small-2 columns large-number">
              <a href={`/project/${p.job_number}`} target="_blank">#{p.job_number}</a>
            </div>
            <div className="small-3 columns">
              <a href={`/project/${p.job_number}`} target="_blank" style={{wordBreak: 'break-word'}}>{p.job_name} (${formatMoney(p.latest_total_amount)})</a><br />
              <small>Created on {formatDate(p.date_created)}</small>
            </div>
            <div className="small-4 columns">
              {!_.isEmpty(p.client_rep) ?
                <div className="rep-block">
                  <img src={getUserImageSrc(p.client_rep)} className="avatar" />
                  <small>Client Rep</small><br />
                  <a href={`/user.php?id=${p.client_rep.user_id}`} target="_blank">{`${p.client_rep.user_first_name} ${p.client_rep.user_last_name}`}</a>
                </div>
              : null}
              {!_.isEmpty(p.sales_rep) ?
                <div className="rep-block">
                  <img src={getUserImageSrc(p.sales_rep)} className="avatar" />
                  <small>Order Rep</small><br />
                  <a href={`/user.php?id=${p.sales_rep.user_id}`} target="_blank">{`${p.sales_rep.user_first_name} ${p.sales_rep.user_last_name}`}</a>
                </div>
              : null}
            </div>
            <div className="small-3 columns projects-icons">
              {p.order_types.map(o => o == 'PRESENTATION' ? <div className="order-icon" key={`presentation-${++icon_index}`}>{o.charAt(0)}</div> : null )}
              {p.order_types.map(o => o == 'ESTIMATE' ? <div className="order-icon" key={`estimate-${++icon_index}`}>{o.charAt(0)}</div> : null )}
              {p.order_types.map(o => o == 'SALES ORDER' ? <div className="order-icon" key={`sales-order-${++icon_index}`}>{o.charAt(0)}</div> : null )}
              {p.order_types.map(o => o == 'INVOICE' ? <div className="order-icon" key={`invoice-${++icon_index}`}>{o.charAt(0)}</div> : null )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const projects = state.entities.projects;
  const contact = state.entities.company_contacts[Object.keys(state.entities.company_contacts)[0]];

  return {
    projects,
    contact
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onloadContactProjects: (projects) => {
      dispatch(loadContactProjects(projects));
    },
    onCreateDeleteTemp: () => {
        dispatch(createDeleteTemp('edit-contact'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactProjects);
